import Head from 'next/head';
import ComingSoon from '../components/comingsoon';

export default function Home() {
  return (
    <>
      <Head>
        <title>Sampoll - Discover New Food & Beverage Products Near You</title>
      </Head>
      <ComingSoon />
    </>
  );
}
