import React from 'react';
import styles from '../styles/comingsoon.module.scss';

const ComingSoon = () => {
  return (
    <div className={styles.comingSoon}>
      <h1 className={styles.heading}>Coming Soon 👀</h1>
      <p className={styles.message}>Get excited fam! The Sampoll marketplace 🛍️ launch is imminent.</p>
    </div>
  );
};

export default ComingSoon;
